import React, { useState } from 'react';
import texts from '@molecules/BottomNavigation/en.json';
import { useRouter } from 'next/router';
import { CollapsibleBar } from '@molecules/BottomNavigation/CollapsibleBar';
import 'animate.css/animate.min.css';
import RmElement from '@molecules/BottomNavigation/RmElement';
import useBottomNavigation from '@hooks/useBottomNavigation';
import { getAuth } from '@services/identity.service';
import NavItems from '@molecules/BottomNavigation/NavItems';
import { navigationGaEventsName } from '@helpers/ga-events-name-enum';

const BottomNavigation = ({ investor, rmDetails, visible = null, setIsBottomNavVisible, showRMDetails }) => {
  const router = useRouter();
  const auth = getAuth();
  const isVisible = visible ?? (texts?.firstLevelPagesWithAuth.indexOf(router?.pathname) !== -1);
  const notShowBottomNavigation = !isVisible || !auth;
  const [viewMoreLinks, setViewMoreLinks] = useState({ links: [], show: false });
  const navItems = useBottomNavigation({auth, investor, defaultNavItems: texts?.navigationLinks, rmDetails, showRMDetails});
  const isAppView = router?.query.is_app == 'true';
  const listedUrlsExceptMoreLinks = texts.navigationLinks.reduce((acc, curr) => {
    acc.push(curr?.redirect)
    return acc;
  }, []);
  const [highlights, setHighlights] = useState('');  

  const redirectTo = (url) => {
    router.push(url);
    setViewMoreLinks([]);
  };

  const collapsibleElements = (id) => {
      const elements = {
        "relationship-manager": <RmElement rmDetails={rmDetails} gTag={navigationGaEventsName.CLICKED_CHAT_WITH_RM_MOBILE_WEB_NAVIGATION} />
      }

      return elements[id];
  };

  if(notShowBottomNavigation || isAppView) return;
  
  return (
      <div className="xl:hidden absolute">
          <NavItems
              {...{
                  navItems,
                  viewMoreLinks,
                  setViewMoreLinks,
                  redirectTo,
                  router,
                  listedUrlsExceptMoreLinks,
                  setIsBottomNavVisible,
                  highlights,
                  setHighlights
              }}
          />
          <CollapsibleBar
              list={viewMoreLinks.links}
              visible={viewMoreLinks.show}
              redirectTo={redirectTo}
              setVisible={(val) => {
                if(!val) setHighlights('');
                setViewMoreLinks(val ? { links: viewMoreLinks, show: true } : {...viewMoreLinks, show: false})
              }}
              collapsibleElements={collapsibleElements}
              router={router}
          />
      </div>
  );
};

export default BottomNavigation;