const buttonTypes = Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    BORDERLESS: 'borderless',
    TERTIARY: 'tertiary',
    TEXT: 'text',
    DEFAULT: 'primary',
});

const buttonSizes = Object.freeze({
    EXTRA_SMALL: 'extraSmall',
    SMALL: 'small',
    REGULAR: 'regular',
    LARGE: 'large',
    DEFAULT: 'regular',
});

const badgeType = Object.freeze({
    WARNING: 'warning',
    SUCCESS: 'success',
    INFO: 'info',
    DANGER: 'danger',
    OUTLINE: 'outline',
    DEFAULT: 'success',
});

const tooltipSize = Object.freeze({
    SMALL: 'small',
    REGULAR: 'regular',
});

const tooltipPlacement = Object.freeze({
    TOP: 'top',
    TOP_START: 'top-start',
    TOP_END: 'top-end',
    RIGHT: 'right',
    RIGHT_START: 'right-start',
    RIGHT_END: 'right-end',
    BOTTOM: 'bottom',
    BOTTOM_START: 'bottom-start',
    BOTTOM_END: 'bottom-end',
    LEFT: 'left',
    LEFT_START: 'left-start',
    LEFT_END: 'left-end',
});

export { buttonTypes, buttonSizes, badgeType, tooltipSize, tooltipPlacement };
