import React from 'react';
import { useRouter } from 'next/router';
import { Image, Text } from '@ui/atoms';
import Button from '@ui/atoms/design-system/Button';
import { buttonSizes, buttonTypes } from '@ui/atoms/utils/enums';
import { cn } from '@ui/atoms/utils/utils';
import texts from '@components/Homepage/en.json';
import { triggerCustomGaEvent } from '@helpers/miscelleanous';
import { bondDirectoryGaEventsName } from '@helpers/ga-events-name-enum';

const BondDirectoryBanner = ({ className }) => {
    const router = useRouter();
    const navigateToBondDirectory = () => {
        triggerCustomGaEvent(
            bondDirectoryGaEventsName.CLICKED_BOND_DIRECTORY_HOME_PAGE
        );
        router.push('/bond-directory');
    };

    const {
        BondDirectoryBannerTitle,
        BondDirectoryBannerDescription,
        ExploreBondDirectory,
    } = texts;

    return (
        <section
            className={cn(
                'relative overflow-hidden bg-primary-500 py-14 mt-16 md:py-10 px-4 md:px-6 xl:px-16',
                className
            )}
        >
            <div className="flex flex-col gap-6 items-center md:items-start max-width-wrapper">
                <div className="flex flex-col gap-3 text-basicWhite">
                    <Text
                        content={BondDirectoryBannerTitle}
                        className="text-center md:text-left h4-semibold md:h3-semibold xl:h2-semibold"
                        isInnerHtml
                    />
                    <Text
                        content={BondDirectoryBannerDescription}
                        className="text-center md:text-left p5-regular md:p4-semibold"
                    />
                </div>
                <Button
                    onClick={navigateToBondDirectory}
                    buttonSize={buttonSizes.LARGE}
                    buttonText={ExploreBondDirectory}
                    buttonType={buttonTypes.TERTIARY}
                    className="!rounded-full"
                />
            </div>
            <Image
                src="/images/homepage/bond-logo.svg"
                className="absolute bottom-0 right-0 md:hidden"
                alt="bond-logo"
            />
            <Image
                src="/images/homepage/bond-logo-web.svg"
                className="absolute bottom-0 right-0 hidden md:block"
                alt="bond-logo-web"
            />
        </section>
    );
};

export default React.memo(BondDirectoryBanner);
