import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * `cn` is a utility function that combines the functionality of `clsx` and `twMerge`.
 * It takes an arbitrary number of inputs, which can be any type that `clsx` accepts (string, object, array, etc.).
 * It first uses `clsx` to combine the class names into a single string.
 * Then it uses `twMerge` to merge the Tailwind CSS classes in the string.
 * This is useful for combining and merging class names in a React component.
 *
 * @param {...(string|object|array)} inputs - The class names to combine and merge.
 * @returns {string} The combined and merged class names.
 */
export function cn(...inputs) {
    return twMerge(clsx(inputs));
}
