import { replaceKeysWithValues } from '@helpers/string';
import { getAuth } from '@services/identity.service';
import { Image, Text } from '@ui/atoms';
import texts from "./en.json";
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import Button from '@ui/atoms/design-system/Button';
import { triggerCustomGaEvent } from '@helpers/miscelleanous';

const RmElement = ({
    rmDetails,
    className = 'p-3 border-t border-gray-200 mt-3 flex flex-col gap-2 w-full',
    whatsAppIcon = texts?.whatsAppLogo,
    buttonType = "primary",
    gTag
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyText = (text) => {
    setIsCopied(true);
    window.navigator.clipboard.writeText(text);
    setTimeout(() => {
        setIsCopied(false);
    }, 2000)
 };  
 
  const whatsappLink = `https://wa.me/${rmDetails.mobile}`;

  return (
      <div className={className}>
          <div className="flex gap-4 items-center">
              <Image src={rmDetails?.profileImageLink} className="w-8 h-8 rounded-full" />
              <Text
                  content={rmDetails?.name}
                  className="p5-medium text-basicBlack"
              />
          </div>
          <div className="flex gap-4 items-center">
              <Text content={texts?.Email} className="p6-regular text-basicBlack" />
              <Text
                  content={rmDetails?.email}
                  className="p6-underline text-primary-500"
              />
          </div>
          {rmDetails?.mobile ?           <div className="flex gap-[10px] items-center">
              <Text content={texts?.Phone} className="p6-regular text-basicBlack" />
              <div className="flex items-center gap-1">
                  <Text
                      content={rmDetails?.mobile}
                      className="p6-medium text-basicBlack"
                  />
                  {isCopied ? (
                      <CheckCircleIcon className="text-semantic-success-base w-4 h-4" />
                  ) : (
                      <button onClick={() => copyText(rmDetails?.mobile)}>
                          <Image
                              src={texts?.CopyIcon}
                              className="w-4 h-4"
                          />
                      </button>
                  )}
              </div>
          </div> : null}

          <a
             href={whatsappLink}
              onClick={() => gTag && triggerCustomGaEvent(gTag)}
              target="_blank"
              className="w-full"
          >
              <Button
                  buttonText={replaceKeysWithValues(texts?.whatsappChatName, {
                      '{name}': rmDetails?.name.split(' ')[0],
                  })}
                  buttonType={buttonType}
                  widthClass="w-full"
                  className="mt-1"
                  buttonSize="extraSmall"
                  prefixComponent={
                      <Image src={whatsAppIcon} className="w-4 h-4" />
                  }
              />
          </a>
          <Text
              content={texts?.availableRmTiming}
              className="p6-regular text-gray-500 text-center"
          />
      </div>
  );
}

export default RmElement;