import { filterMyReferralsLink, filterRelationshipManagerLink } from '@helpers/bottom-navigation-helpers';
import { isIfa } from '@helpers/utils/investor-utils';
import { useEffect, useState } from 'react';

const useBottomNavigation = ({ auth, investor, defaultNavItems, rmDetails = null, showRMDetails = false }) => {
  const [navItems, setNavItems] = useState([]);
  const showReferral = !isIfa(auth)

  useEffect(() => {
    const filterNavItems = () => {
      let newNavItemList =  JSON.parse(JSON.stringify(defaultNavItems));
      newNavItemList = filterRelationshipManagerLink(newNavItemList, rmDetails, showRMDetails);
      newNavItemList = filterMyReferralsLink(newNavItemList, showReferral);

      setNavItems(newNavItemList);
    };

    filterNavItems();
  }, [investor, rmDetails]);

  return navItems;
};

export default useBottomNavigation;