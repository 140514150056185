const ITEM_IDS = {
    MORE: 'more',
    ORDERS: 'orders'
};
  
const LINK_IDS = {
    RELATIONSHIP_MANAGER: 'relationship-manager',
    MY_REFERRALS: 'my-referrals',
};  

export const filterRelationshipManagerLink = (items, rmDetails, showRMDetails) => {
    if (!showRMDetails || !rmDetails) {
      return items.map((item) => {
        if (item.id === ITEM_IDS.MORE && item.moreLinks) {
          item.moreLinks = item.moreLinks.filter((link) => link.id !== LINK_IDS.RELATIONSHIP_MANAGER);
        }
        return item;
      });
    }
    return items;
};
  
export const filterMyReferralsLink = (items, showReferral) => {
    if (!showReferral) {
      return items.map((item) => {
        if (item.id === ITEM_IDS.MORE && item.moreLinks) {
          item.moreLinks = item.moreLinks.filter((link) => link.id !== LINK_IDS.MY_REFERRALS);
        }
        return item;
      });
    }
    return items;
  };