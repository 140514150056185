import { triggerCustomGaEvent } from '@helpers/miscelleanous';
import { Image, Text } from '@ui/atoms';
import React, { useEffect, useState } from 'react'

const NavItems = ({
    navItems,
    viewMoreLinks,
    setViewMoreLinks,
    redirectTo,
    listedUrlsExceptMoreLinks,
    router,
    setIsBottomNavVisible,
    highlights,
    setHighlights
}) => {

  useEffect(() => { 
    setIsBottomNavVisible(true) 

    return () => {
         setIsBottomNavVisible(false)
    }
  }, [])

  const handleClick = (item) => {
    setHighlights(item?.id)
    if(item?.gTag) triggerCustomGaEvent(item?.gTag);
    if(item?.redirect){
        redirectTo(item.redirect)
    }else{
        setViewMoreLinks({ links: item.moreLinks, show: !(viewMoreLinks.show) })
    }
  }

  const getImageSrc = (item, highlights, listedUrlsExceptMoreLinks, routerPathname) => {
    const isRedirectMatch = item?.redirect === routerPathname;
    const isMoreLink = !listedUrlsExceptMoreLinks.includes(routerPathname) && item?.id === 'more';
  
    return (highlights ? highlights === item?.id : (isRedirectMatch || isMoreLink))
      ? item.activeImage
      : item.image;
  };

  const getTextClassNames = (item, highlights, routerPathname) => {
    const isRedirectMatch = item?.redirect === routerPathname;
    const textColor = (highlights ? highlights === item?.id : isRedirectMatch) ? 'text-primary-500' : 'text-gray-500';
  
    return `p7-medium text-center ${textColor}`;
  };
  

  return (
    <div className={`bg-basicWhite fixed bottom-0 w-full grid-cols-${navItems.length} grid gap-1 px-3 xl:hidden shadow-bottom-sheet`} style={{
        zIndex: 99
      }}>
        {navItems.map((item, index) => (
          <div
            key={index}
            className="flex justify-center items-center flex-col gap-2 bg-basicWhite/80 relative z-10 py-3 cursor-pointer"
            onClick={() => handleClick(item)}
          >
            <div className="relative">
              <Image
                src={getImageSrc(
                    item,
                    highlights,
                    listedUrlsExceptMoreLinks,
                    router.pathname
                )}
                width={24}
                height={24}
              />
            </div>
            <Text
              content={item.label}
              className={getTextClassNames(item, highlights, router.pathname)}
            />
          </div>
        ))}
      </div>
  )
}

export default NavItems;